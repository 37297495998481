import * as ES6Promise from "es6-promise";
ES6Promise.polyfill();

import Dev from './global/Dev';
import Header from './global/Header';
// import { HistoryManager } from './andreasoby/controllers/HistoryManager';
// import { PageTransition } from './andreasoby/controllers/PageTransition';
import LazyLoad from './andreasoby/controllers/Lazyload';
import AnchorLinks from './global/AnchorLinks';

interface ModuleToLoad {
  selector: string,
  promise: any
}

//@ts-ignore
window.loadExhibition = async (): Promise<any> => {
  return await import(/* webpackChunkName: "exhibition" */ './modules/Exhibition');
}
//@ts-ignore
window.loadExtensions = async (): Promise<any> => {
  return await import(/* webpackChunkName: "extensions" */ './modules/Extensions');
}
//@ts-ignore
window.loadMediaSlideshow = async (): Promise<any> => {
  return await import(/* webpackChunkName: "mediaSlideshow" */ './modules/MediaSlideshow');
}
//@ts-ignore
window.loadMedia = async (): Promise<any> => {
  return await import(/* webpackChunkName: "media" */ './modules/Media');
}
//@ts-ignore
window.loadAudio = async (): Promise<any> => {
  return await import(/* webpackChunkName: "audio" */ './modules/Audio');
}
//@ts-ignore
window.loadVideo = async (): Promise<any> => {
  return await import(/* webpackChunkName: "video" */ './modules/Video');
}
//@ts-ignore
window.loadImageAndCaption = async (): Promise<any> => {
  return await import(/* webpackChunkName: "imageAndCaption" */ './modules/ImageAndCaption');
}
//@ts-ignore
window.loadMediaAndCaption = async (): Promise<any> => {
  return await import(/* webpackChunkName: "mediaAndCaption" */ './modules/MediaAndCaption');
}
//@ts-ignore
window.loadHeadline = async (): Promise<any> => {
  return await import(/* webpackChunkName: "headline" */ './modules/Headline');
}
//@ts-ignore
window.loadText = async (): Promise<any> => {
  return await import(/* webpackChunkName: "text" */ './modules/Text');
}
//@ts-ignore
window.loadIngress = async (): Promise<any> => {
  return await import(/* webpackChunkName: "ingress" */ './modules/Ingress');
}
//@ts-ignore
window.loadExhibitionOverview = async (): Promise<any> => {
  return await import(/* webpackChunkName: "exhibitionOverview" */ './modules/ExhibitionOverview');
}
//@ts-ignore
window.loadExhibitionTeaser = async (): Promise<any> => {
  return await import(/* webpackChunkName: "exhibitionTeaser" */ './modules/ExhibitionTeaser');
}
//@ts-ignore
window.loadStoryOverview = async (): Promise<any> => {
  return await import(/* webpackChunkName: "storyOverview" */ './modules/StoryOverview');
}
//@ts-ignore
window.loadPostTeaser = async (): Promise<any> => {
  return await import(/* webpackChunkName: "postTeaser" */ './modules/PostTeaser');
}
//@ts-ignore
window.loadOnlineWork = async (): Promise<any> => {
  return await import(/* webpackChunkName: "onlineWork" */ './modules/OnlineWork');
}
//@ts-ignore
window.loadColumns = async (): Promise<any> => {
  return await import(/* webpackChunkName: "columns" */ './modules/Columns');
}
//@ts-ignore
window.loadLogo = async (): Promise<any> => {
  return await import(/* webpackChunkName: "logo" */ './modules/Logo');
}

class Main {

  // private _history_manager: HistoryManager;
  // private _page_transition: PageTransition;
  private _lazy_load: LazyLoad;

  constructor() {

    document.addEventListener('DOMContentLoaded', () => this.init());

  }


  private init() {

    if ( !document.body.classList.contains( 'wp-admin' ) ) {
     
      // this._history_manager = new HistoryManager( [ '.c-main__inner', '.c-main-nav__list', '.c-footer .c-main-nav__list' ] );
      // this._page_transition = new PageTransition();
      this._lazy_load = new LazyLoad();
      
      this.setupListeners();
      this.setupContent();
      this.checkIfTouch();
    
      new Header();
      new AnchorLinks();
      new Dev();
      // new Newsletter();

      // Add custom cursor if user isn't using a touch device
      if ( this.checkIfTouch() ) {
        
        document.body.classList.add( 'touch-device' );
        
      }
      
    }
    
  }


  private checkIfTouch() {

    try {

      document.createEvent("TouchEvent");  
      return true;

    } catch (e) {

      return false;

    }  

  }


  private setupListeners() {

    window.addEventListener( 'ajaxLinkClicked', ( event: CustomEvent ) => {

      // this._page_transition.hideContent( event );

    } );

    window.addEventListener( 'ajaxSucceeded', ( event: Event ) => this.ajaxSucceeded( event ) );
    window.addEventListener( 'newContentInserted', () => this.setupContent() );
    window.addEventListener( 'contentUpdated', () => this.updateContent() );
    window.addEventListener( 'contentFullyRevealed', () => this.contentFullyRevealed() );

    window.addEventListener( 'popstate' , ( event: PopStateEvent ) => {
        
        // this._page_transition.hideContent();
        // this._page_transition.injectContent();

    } );

  }


  private ajaxSucceeded( event: Event ) {

    // this._page_transition.injectContent();

  }


  private updateContent() {

    // this._history_manager.setupNewLinks();

  }


  private contentFullyRevealed() {

    

  }
  
  
  private setupContent() {
    
    const modules: Array<ModuleToLoad> = [
      { selector: '.c-exhibition', promise: 'loadExhibition' },
      { selector: '.c-extensions', promise: 'loadExtensions' },
      { selector: '.c-media-slideshow', promise: 'loadMediaSlideshow' },
      { selector: '.c-media', promise: 'loadMedia' },
      { selector: '.c-image-and-caption', promise: 'loadImageAndCaption' },
      { selector: '.c-media-and-caption', promise: 'loadMediaAndCaption' },
      { selector: '.c-audio', promise: 'loadAudio' },
      { selector: '.c-video', promise: 'loadVideo' },
      { selector: '.c-text', promise: 'loadText' },
      { selector: '.c-ingress', promise: 'loadIngress' },
      { selector: '.c-headline', promise: 'loadHeadline' },
      { selector: '.c-exhibition-overview', promise: 'loadExhibitionOverview' },
      { selector: '.c-exhibition-teaser', promise: 'loadExhibitionTeaser' },
      { selector: '.c-post-teaser', promise: 'loadPostTeaser' },
      { selector: '.c-story-overview', promise: 'loadStoryOverview' },
      { selector: '.c-online-work', promise: 'loadOnlineWork' },
      { selector: '.c-columns', promise: 'loadColumns' },
      { selector: '.c-logo', promise: 'loadLogo' },
    ];

    const queue: Array<any> = [];
    for ( let i: number = 0; i < modules.length; i++ ) {

      const element: HTMLElement = document.querySelector( modules[ i ].selector );
      //@ts-ignore
      if ( element ) queue.push( window[modules[ i ].promise]() );

    }

    Promise.all( queue ).then( ( modules: any ) => {
      
      for ( let i: number = 0; i < modules.length; i++ ) {
        
        // Get dynamic constructor name
        const name: string = Object.keys(modules[ i ] )[0];
        new modules[ i ][ name ];

      }

      this._lazy_load.updateContent();
      // this._page_transition.showContent();

    });

  }

}

new Main();