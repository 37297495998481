export default class Header {

    private _body_element: HTMLElement;
    private _menu_toggle_element: HTMLElement;
    private _page_intro_element: HTMLElement;
    private _page_intro_element_height: number;
    
    private _menu_open: boolean = false;
    private _scroll_position: number = 0;
    private _scroll_down_start: number = 0;
    private _scroll_disabled: boolean = false;
    private _scroll_top: number = 0;

    constructor() {

      this.init();

    }


    private init() {

        this._body_element = document.body;
        this._menu_toggle_element = document.querySelector( '.c-main-nav__toggle' );
        this._page_intro_element = document.querySelector( '.c-page__intro-container' );
        if ( this._menu_toggle_element ) {

          this._menu_toggle_element.addEventListener( 'click', ( event: Event ) => this.hamburgerClicked( event ) );
        //   this._menu_toggle_element.click();

        }

        window.addEventListener( 'closeMenu', ( event: Event) => this.closeMenu() );
        
        if ( this._page_intro_element ) {
            
            this.handleWindowResize();
            window.addEventListener( 'resize', ( event: Event) => this.handleWindowResize() );
            window.addEventListener( 'load', ( event: Event) => this.handleWindowResize() );
            window.addEventListener( 'scroll', ( event: Event) => this.handleScroll() );

        }


        const hamburger_element: HTMLElement = document.querySelector( '.c-hamburger__link' );
        if ( hamburger_element ) {

            hamburger_element.addEventListener( 'click', ( event: Event ) => this.hamburgerClicked( event ) );

        }
    }


    private hamburgerClicked( event: Event ) {

        event.preventDefault();

        this._menu_open = !this._menu_open;

        if ( this._menu_open) {

            this.disableScroll();
            document.body.classList.add( 's-menu-open' );
            
        } else {
            
            this.closeMenu();

        }


        return false;
        
    }


    private closeMenu() {

        this._menu_open = false;

        document.body.classList.remove( 's-menu-open' );
        
        this.enableScroll();

    } 


    private handleScroll() {
        
        const scroll: number = window.pageYOffset;
        if ( scroll > this._page_intro_element_height + 100) {

            this._body_element.classList.add( 's-show-logo' );

        } else {

            this._body_element.classList.remove( 's-show-logo' );

        }
        
        this._scroll_position = window.pageYOffset;

    }


    private disableScroll() {
    
        if ( this._scroll_disabled ) return;
        
        this._scroll_top = window.pageYOffset || document.documentElement.scrollTop;
        document.body.style.top = `${-1 * this._scroll_top}px`;

        this._scroll_disabled = true;

    }


    private enableScroll() {

        if ( !this._scroll_disabled ) return;
    
        window.scrollTo( 0, this._scroll_top );
        document.body.style.top = '';
    
        this._scroll_disabled = false;

    }


    private handleWindowResize() {

        this._page_intro_element_height = this._page_intro_element.clientHeight;

    }

}