export default class AnchorLinks {

  constructor() {

    const anchor_links: NodeListOf<HTMLAnchorElement> = document.querySelectorAll( 'a[href*="#"]:not([href="#0"])' );
    for ( let i: number = 0; i < anchor_links.length; i++ )
      anchor_links[ i ].addEventListener( 'click', ( event: MouseEvent ) => this.handleAnchorLinkClicked( event ) );

  }


  private handleAnchorLinkClicked( event: MouseEvent ) {

    event.preventDefault();

    let target: HTMLAnchorElement;
    // @ts-ignore;
    for ( let i: number = 0; i < event.path.length; i++ ) {
      // @ts-ignore;
      if ( event.path[ i ].hasAttribute( 'href' ) ) {
        
        // @ts-ignore;
        target = event.path[ i ];
        break;

      }

    }

    const href: string = target.getAttribute( 'href' );
    const element_to_scroll_to: HTMLElement = document.querySelector( href );
    if ( element_to_scroll_to ) {

      element_to_scroll_to.scrollIntoView( { behavior: 'smooth' } );

    }

    return false;

  }

}