export default class Dev {

  private _dev_element: HTMLElement;
  private _ctrl_pressed: boolean = false;

  constructor() {

    this._dev_element = document.querySelector( '.c-dev' );
    if ( this._dev_element ) {

      const toggle_element: HTMLElement = this._dev_element.querySelector( '.c-dev__toggle' );
      if ( toggle_element ) toggle_element.addEventListener( 'click', () => this.handleToggleClicked() );
      
      // this.handleToggleClicked();

      document.addEventListener("keydown", ( event ) => {

        if ( event.key === "Control" ) this._ctrl_pressed = true;
  
      } );
      
      document.addEventListener("keyup", ( event ) => {
  
        if ( event.key === "Control" ) this._ctrl_pressed = false;
        if ( event.key === 'l' && this._ctrl_pressed ) this.handleToggleClicked();
  
      } );
    
    }

  }


  private handleToggleClicked() {

    this._dev_element.classList.toggle( 's-active' );

  }

}